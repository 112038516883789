import React, { Component } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { SiteContext } from './Layout';
import SHeader from '../styles/organisms/SHeader';
import Wrapper from '../styles/utilities/Wrapper';
import { colors, misc } from '../styles/utilities/settings';
import { above } from '../styles/utilities/mediaQueries';
import MenuItem from './atoms/MenuItem';

class Header extends Component {
  state = {
    isOpen: false,
  };

  render() {
    const { isOpen } = this.state;
    return (
      <SiteContext.Consumer>
        {value => (
          <SHeader>
            <Wrapper>
              <nav className={isOpen ? 'open' : null}>
                <Link to="/" className="logo">
                  <img src={value.global.logo} alt={value.global.site_title} />
                </Link>
                <MenuToggle
                  className={isOpen ? 'open' : null}
                  href={null}
                  onClick={() => {
                    this.setState({
                      isOpen: !isOpen,
                    });
                  }}
                >
                  <span />
                  <span />
                  <span />
                </MenuToggle>
                <div className="menu">
                  <StaticQuery
                    query={HEADER_QUERY}
                    render={data => (
                      <ul>
                        {data.settingsYaml.siteMenu.map(
                          menu =>
                            menu.title === 'Main Nav' &&
                            menu.menuItems.map(item => (
                              <MenuItem content={item} key={item.title} />
                            ))
                        )}
                      </ul>
                    )}
                  />
                  <div className="top-bar">
                    <a href={`tel:${value.global.phone}`}>
                      <i className="fa fa-phone" />
                      {` ${value.global.phone}`}
                    </a>
                    <a
                      href={`https://www.google.com/maps/dir//${value.global.address.street} ${value.global.address.cityStateZip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-map-marker" />
                      {` ${value.global.address.street} ${value.global.address.cityStateZip}`}
                    </a>
                  </div>
                </div>
              </nav>
            </Wrapper>
          </SHeader>
        )}
      </SiteContext.Consumer>
    );
  }
}

export default Header;

const HEADER_QUERY = graphql`
  {
    settingsYaml(sectionName: { eq: "menus" }) {
      siteMenu {
        title
        menuItems {
          title
          url
          externalUrl
          extraClasses
        }
      }
    }
  }
`;

const MenuToggle = styled.a`
  width: 30px;
  cursor: pointer;
  position: absolute;
  z-index: 101;
  top: 50%;
  right: 15px;
  height: 16px;
  transform: translateY(-50%);
  transition-duration: ${misc.animSpeed};

  ${above.ipadLand`
    display: none;
  `}

  &.open {
    top: 20%;
    transform: rotate(90deg);

    span {
      &:nth-child(2) {
        opacity: 0;
        right: -15px;
      }

      &:nth-child(1) {
        top: 7px;
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        bottom: 7px;
      }
    }
  }

  span {
    display: block;
    height: 2px;
    width: 100%;
    right: 0;
    opacity: 1;
    position: relative;
    background-color: ${colors.blue};
    transition-duration: ${misc.animSpeed};

    + span {
      margin-top: 5px;
    }
  }
`;
