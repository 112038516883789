import { css } from 'styled-components';
import { colors, misc } from './settings';
import { above } from './mediaQueries';

export const typography = css`
  line-height: 1.3;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  p,
  blockquote {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
  }

  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }

  ul,
  ol {
    margin-left: 15px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  a {
    color: ${colors.blue};
    text-decoration: underline;

    &:hover {
      ${above.ipadPort`
        color: ${colors.orange};
      `}
    }
  }

  blockquote {
    background-color: ${colors.lightGray};
    padding: 20px;
    border-left: 20px solid ${colors.gray};
  }

  hr {
    margin: 40px 0;
    max-width: 80%;
  }
`;

export const button = css`
  display: inline-block;
  text-decoration: none;
  padding: 20px 25px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition-duration: ${misc.animSpeed};
  text-align: center;
`;

export const whiteButton = css`
  ${button};
  background-color: ${colors.white};
  color: ${colors.lightBlue};

  &:hover {
    ${above.ipadPort`
      background-color: ${colors.blue};
      color: ${colors.white};
    `}
  }
`;

export const blueButton = css`
  ${button};
  background-color: ${colors.blue};
  color: ${colors.white};

  &:hover {
    ${above.ipadPort`
      background-color: ${colors.white};
      color: ${colors.blue};
    `}
  }
`;

export const mediumBlueButton = css`
  ${button};
  background-color: ${colors.mediumBlue};
  color: ${colors.white};

  &:hover {
    ${above.ipadPort`
      background-color: ${colors.white};
      color: ${colors.mediumBlue};
    `}
  }
`;

export const lightBlueButton = css`
  ${button};
  background-color: ${colors.lightBlue};
  color: ${colors.white};

  &:hover {
    ${above.ipadPort`
      background-color: ${colors.white};
      color: ${colors.mediumBlue};
    `}
  }
`;

export const gatsbyBG = css`
  position: relative;
  z-index: 1;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    z-index: 0;
  }
`;
