import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import ImgFile from '../atoms/ImgFile';
import { SContactBlock } from './ContactBlock';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, breakpoints } from '../../styles/utilities/settings';

const PhotoBanner = ({ content }) => (
  <SContactBlock>
    <Wrapper narrow>
      <SPhotoBanner>
        <MobileTitle>{content.heading}</MobileTitle>
        <ImgFile image={content.image} />
        <div className="copy">
          <h1>{content.heading}</h1>
          <ReactMarkdown source={content.copy} />
        </div>
      </SPhotoBanner>
    </Wrapper>
  </SContactBlock>
);

export default PhotoBanner;

const MobileTitle = styled.h1`
  color: ${colors.white};

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`;

const SPhotoBanner = styled.div`
  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: flex;
    align-items: center;
  }

  ${MobileTitle} {
    margin-bottom: 10px;
  }

  .gatsby-image-wrapper {
    @media screen and (min-width: ${breakpoints.mobile}px) {
      width: 150px;
    }

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 300px;
    }
  }

  .copy {
    color: ${colors.white};
    line-height: 1.5;
    font-size: 14px;

    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      margin-top: 20px;
    }

    @media screen and (min-width: ${breakpoints.mobile}px) {
      width: calc(100% - 150px);
      padding-left: 20px;
    }

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      font-size: 18px;
      width: calc(100% - 300px);
      padding-left: 50px;
    }

    h1 {
      margin-bottom: 20px;

      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        display: none;
      }
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
