import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { SiteContext } from './Layout';
import { colors } from '../styles/utilities/settings';
import Wrapper from '../styles/utilities/Wrapper';
import MenuItem from './atoms/MenuItem';
import { above, below } from '../styles/utilities/mediaQueries';

const Footer = () => (
  <SiteContext.Consumer>
    {value => (
      <SFooter>
        <Wrapper narrow>
          <div>
            <h3>Contact Us</h3>
            <div className="content">
              <p
                dangerouslySetInnerHTML={{
                  __html: `${value.global.address.street}</br>${value.global.address.cityStateZip}`,
                }}
              />
              <br />
              <p>
                <a href={`tel:${value.global.phone}`}>{value.global.phone}</a>
              </p>
            </div>
          </div>
          <div>
            <h3>Hours</h3>
            <div className="content">
              <ReactMarkdown source={value.global.hours} />
            </div>
          </div>

          <StaticQuery
            query={FOOTER_QUERY}
            render={data => (
              <>
                {data.settingsYaml.siteMenu.map(
                  menu =>
                    menu.title === 'Affiliations' && (
                      <div key={menu.title}>
                        <h3>{menu.title}</h3>
                        <div className="content">
                          <ul>
                            {menu.menuItems.map(item => (
                              <MenuItem key={item.title} content={item} />
                            ))}
                          </ul>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          />
        </Wrapper>
      </SFooter>
    )}
  </SiteContext.Consumer>
);

export default Footer;

const FOOTER_QUERY = graphql`
  {
    settingsYaml(sectionName: { eq: "menus" }) {
      siteMenu {
        title
        menuItems {
          title
          url
          externalUrl
        }
      }
    }
  }
`;

const SFooter = styled.footer`
  background-color: ${colors.mediumBlue};
  color: ${colors.white};
  padding: 50px 0;
  font-size: 14px;
  line-height: 1.5;

  ${Wrapper} {
    display: flex;

    ${above.ipadPort`
      justify-content: space-between;
    `}

    ${below.ipadPort`
      flex-wrap: wrap;
    `}

    > div {
      ${above.mobile`
        align-items: flex-start;
        display: flex;
      `}

      ${below.ipadPort`
        width: 100%;
        justify-content: center;

        + div {
          margin-top: 50px;
        }
      `}
    }
  }

  h3 {
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

    ${below.ipadPort`
      width: 100px;
    `}
  }

  a {
    color: ${colors.white};
  }

  .content {
    ${below.ipadPort`
      width: 200px;
    `}
  }
`;
