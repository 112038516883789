import React from 'react';
import ReactMarkdown from 'react-markdown';
import { PageContext } from '../templates/Page';
import ImgFile from '../atoms/ImgFile';
import Wrapper from '../../styles/utilities/Wrapper';
import SHeroBanner from '../../styles/organisms/SHeroBanner';
import Arrow from '../atoms/Arrow';

const HeroBanner = ({ content }) => (
  <PageContext.Consumer>
    {value => (
      <SHeroBanner>
        <ImgFile image={value.pageImage} />
        <Wrapper>
          <div className="content">
            <h1>{content.heading}</h1>
            {content.copy && <ReactMarkdown source={content.copy} />}
            <Arrow content={content.arrow} />
          </div>
        </Wrapper>
      </SHeroBanner>
    )}
  </PageContext.Consumer>
);

export default HeroBanner;
