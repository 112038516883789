export const colors = {
  black: '#000000',
  white: '#ffffff',
  blueWhite: '#f1f9ff',
  gray: '#e2e2e2',
  lightGray: '#f8f8f8',
  darkGray: '#9d9fa2',
  darkerGray: '#636466',
  green: '#82ad41',
  lightGreen: '#a7c57a',
  orange: '#d57824',
  blue: '#1a77b9',
  mediumBlue: '#4593c1',
  lightBlue: '#6eaccd',
  darkBlue: '#002F5F',
  red: '#b80000',
  whiteOverlay: 'rgba(255, 255, 255, 0.2)',
  blueSpotlightStart: 'rgba(68, 147, 193, 1)',
  blueSpotlightMiddle: 'rgba(120, 177, 210, .6)',
  blueSpotlightEnd: 'rgba(120, 177, 210, .0)',
  blueGradientStart: 'rgba(13, 60, 93, 1)',
  blueGradientMiddle: 'rgba(15, 67, 104, .6)',
  blueGradientEnd: 'rgba(26, 119, 185, .0)',
};

export const breakpoints = {
  mobile: 480,
  wrapperNarrow: 880,
  ipadLand: 1085,
  headerBreak: 1140,
  ipadMid: 960,
  ipadPort: 768,
  pageWidth: 1240,
  mapBreak1: 1660,
};

export const misc = {
  animSpeed: '.3s',
};
