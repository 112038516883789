import React from 'react';
import { SContactBlock } from './ContactBlock';
import Wrapper from '../../styles/utilities/Wrapper';
import { SServiceAccordion } from '../molecules/ServiceAccordion';

const FileList = ({ content }) => (
  <SContactBlock>
    <Wrapper>
      <div className="content">
        <h1>{content.heading}</h1>
      </div>

      <SServiceAccordion>
        {content.files.map(file => (
          <a
            key={file.title}
            href={file.file}
            target="_blank"
            rel="noopener noreferrer"
            className="file"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
            >
              <g
                id="Group_15"
                data-name="Group 15"
                transform="translate(-1005 -516)"
              >
                <circle
                  id="Ellipse_49"
                  data-name="Ellipse 49"
                  cx="14"
                  cy="14"
                  r="14"
                  transform="translate(1005 516)"
                  fill="#4593c1"
                />
                <g id="Group_14" data-name="Group 14">
                  <g
                    id="Group_13"
                    data-name="Group 13"
                    transform="translate(-0.5)"
                  >
                    <line
                      id="Line_2"
                      data-name="Line 2"
                      x2="7"
                      y2="7"
                      transform="translate(1013.5 528.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1.4"
                    />
                    <line
                      id="Line_4"
                      data-name="Line 4"
                      x1="7"
                      y2="7"
                      transform="translate(1019.5 528.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1.4"
                    />
                  </g>
                  <line
                    id="Line_3"
                    data-name="Line 3"
                    y1="10"
                    transform="translate(1019.5 524.5)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1.4"
                  />
                </g>
              </g>
            </svg>

            <h4>{file.title}</h4>
          </a>
        ))}
      </SServiceAccordion>
    </Wrapper>
  </SContactBlock>
);

export default FileList;
