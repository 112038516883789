import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ImgFile from './ImgFile';
import { TeamContext } from '../organisms/TeamList';
import { gatsbyBG } from '../../styles/utilities/elements';
import { colors, misc, breakpoints } from '../../styles/utilities/settings';
import { above } from '../../styles/utilities/mediaQueries';

const TeamTile = ({ content, index }) => (
  <TeamContext.Consumer>
    {value => (
      <STeamTile>
        <AnchorLink
          href="#teamContent"
          className={value.activeIndex === index ? 'active' : null}
          onClick={() => {
            value.updateIndex(index);
          }}
        >
          <ImgFile image={content.frontmatter.image} />
          <div className="name">
            <p>{content.frontmatter.title}</p>
          </div>
        </AnchorLink>
      </STeamTile>
    )}
  </TeamContext.Consumer>
);

export default TeamTile;

export const STeamTile = styled.div`
  position: relative;
  cursor: pointer;

  ${above.mobile`
    padding-right: 10px;
  `}

  a {
    display: block;
    margin-bottom: 10px;

    @media screen and (min-width: ${breakpoints.mobile}px) {
      height: 240px;
      ${gatsbyBG};
    }

    @media (max-width: ${breakpoints.ipadPort -
        1}px) and (min-width: ${breakpoints.mobile}px) {
      height: 470px;
    }

    &.active,
    &:hover {
      .name {
        opacity: 0;
      }
    }
  }

  .name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    padding: 6px;
    text-align: center;
    display: flex;
    line-height: 1.4;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightBlue};
    transition-duration: ${misc.animSpeed};

    p {
      display: inline-block;
      color: ${colors.white};
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
`;
