import styled from 'styled-components';
import { gatsbyBG, typography, whiteButton } from '../utilities/elements';
import { above, below } from '../utilities/mediaQueries';
import { colors } from '../utilities/settings';
import Wrapper from '../utilities/Wrapper';
import { hoefler } from '../utilities/fonts';

const SHeroBanner = styled.div`
  ${gatsbyBG};

  ${above.ipadPort`
    height: calc(100vh - 118px);
  `}

  &:before {
    content: '';
    display: block;
  ${({ spotlight }) =>
    spotlight
      ? `background: linear-gradient(to right, ${colors.blueSpotlightStart} 0%, ${colors.blueSpotlightMiddle} 30%, ${colors.blueSpotlightEnd} 70%);`
      : `background: linear-gradient(to right, ${colors.blueGradientStart} 0%, ${colors.blueGradientMiddle} 60%, ${colors.blueGradientEnd} 100%);`};
    height: 100%;
    width: 66.6666%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;


    ${below.ipadPort`
  ${({ spotlight }) =>
    spotlight
      ? `background: linear-gradient(to right, ${colors.blueSpotlightStart} 0%, ${colors.blueSpotlightMiddle} 100%);`
      : `background: linear-gradient(to right, ${colors.blueGradientStart} 0%, ${colors.blueGradientMiddle} 100%);`};
      width: 100%;
      `}
  }

  ${Wrapper} {
    position: relative;
    z-index: 1;
    height: 100%;

    ${above.ipadPort`
      display: flex;
      align-items: center;
    `}

    ${below.ipadPort`
      padding: 60px 15px;
    `}
  }


  .content {
    color: ${colors.white};
    width: 100%;
    ${typography};
    line-height: 1.4;

    ${above.ipadPort`
      max-width: 450px;
      `}

    ${below.ipadPort`
    ${({ contentBlock }) =>
      contentBlock ? 'width: 100%; margin-bottom: 30px;' : 'width: 100%;'};
    `}

    ${below.mobile`
      width: 100%;
  `}

    &.short {
      ${above.ipadPort`
      max-width: 400px;
      `}
    }

    h1 {
      ${hoefler};
      font-size: 55px;
      line-height: 1.1;
    }

    > a {
      ${whiteButton};
      margin-top: 50px;

      ${below.mobile`
        display: block;
      `}
    }
  }

  .arrow {
    position: absolute;
    left: 30px;
    bottom: 40px;

    ${below.ipadPort`
      display: none;
    `}

    svg {
      ${above.ipadPort`
        position: absolute;
        bottom: 20px;
      `}
    }
  }

  aside {
    background-color: ${colors.lightBlue};
    color: ${colors.white};
    width: 100%;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    position: relative;
    z-index: 2;

    ${above.ipadPort`
      position: absolute;
      right: 0;
      bottom: 30px;
      max-width: 450px;
    `}

    ${below.ipadPort`
      border-top: 1px solid ${colors.white};
    `}
  }
`;

export default SHeroBanner;
