import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { ServiceContext } from '../organisms/ServiceList';
import { colors, misc } from '../../styles/utilities/settings';
import { typography } from '../../styles/utilities/elements';
import { above, below } from '../../styles/utilities/mediaQueries';

const ServiceAccordion = () => (
  <ServiceContext.Consumer>
    {value => (
      <SServiceAccordion>
        {value.services.map((service, index) => (
          <a
            key={service.title}
            href={null}
            onClick={() => {
              if (value.activeIndex === index) {
                value.updateIndex(null);
              } else {
                value.updateIndex(index);
              }
            }}
            className={`service${value.activeIndex === index ? ' open' : ''}`}
          >
            <div className="indicator">
              <span />
              <span />
            </div>
            <h4>{service.title}</h4>
            <div className="description">
              <ReactMarkdown source={service.body} />
            </div>
          </a>
        ))}
      </SServiceAccordion>
    )}
  </ServiceContext.Consumer>
);

export default ServiceAccordion;

export const SServiceAccordion = styled.div`
  background-color: ${colors.blueWhite};
  padding: 40px 20px;
  width: 100%;

  ${above.ipadPort`
    margin-left: 50px;
    max-width: 650px;
    padding: 40px;
  `}

  ${above.ipadLand`
    padding: 70px;
  `}

  .file {
    background-color: ${colors.white};
    padding: 25px 20px;
    display: flex;
    align-items: center;
    transition-duration: ${misc.animSpeed};

    ${above.ipadPort`
      align-items: center;
    `}

    &:hover {
      ${above.ipadPort`
        opacity: 0.6;
      `}
    }

    svg {
      margin-right: 20px;
      width: 28px;

      ${below.ipadPort`
        margin-top: 5px;
      `}
    }
  }

  .service {
    background-color: ${colors.white};
    padding: 25px 20px 25px 80px;
    position: relative;

    + .service {
      margin-top: 20px;
    }

    .indicator {
      background-color: ${colors.mediumBlue};
      color: ${colors.white};
      position: absolute;
      top: 20px;
      left: 20px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 30px;
      border-radius: 100%;

      span {
        display: block;
        height: 2px;
        width: 50%;
        background-color: ${colors.white};
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition-duration: ${misc.animSpeed};

        + span {
          transform: translateX(-50%) translateY(-50%) rotate(90deg);
        }
      }
    }

    &.open {
      .indicator {
        span {
          + span {
            transform: translateX(-50%) translateY(-50%) rotate(0);
          }
        }
      }

      .description {
        max-height: 500px;
        margin-top: 20px;
      }
    }
  }
  .description {
    max-height: 0;
    overflow: hidden;
    transition-duration: ${misc.animSpeed};
    ${typography};

    ${below.ipadLand`
      margin-left: -30px;
    `}
  }

  h4 {
    color: ${colors.mediumBlue};
    font-weight: 600;
    font-size: 18px;
    width: calc(100% - 28px);
  }

  a {
    cursor: pointer;
    display: block;
  }
`;
