import React from 'react';
import { Link } from 'gatsby';
import { PageContext } from '../templates/Page';
import ImgFile from '../atoms/ImgFile';
import Wrapper from '../../styles/utilities/Wrapper';
import SHeroBanner from '../../styles/organisms/SHeroBanner';
import Arrow from '../atoms/Arrow';

const SpotlightBanner = ({ content }) => (
  <PageContext.Consumer>
    {value => (
      <SHeroBanner spotlight>
        <ImgFile image={value.pageImage} />
        <Wrapper>
          <div className="content short">
            <h1>{content.heading}</h1>
            {content.copy && <p>{content.copy}</p>}
            {content.link && (
              <Link to={`/${content.link}`}>{content.linkCopy}</Link>
            )}
          </div>
          <Arrow content={content.arrow} />
        </Wrapper>
        {content.name && <aside>{content.name}</aside>}
      </SHeroBanner>
    )}
  </PageContext.Consumer>
);

export default SpotlightBanner;
