import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import GlobalStyle from '../styles/Global';

export const SiteContext = React.createContext();

const Layout = ({ children }) => (
  <StaticQuery
    query={SITE_QUERY}
    render={data => (
      <SiteContext.Provider
        value={{
          global: data.settingsYaml,
          files: data.allFile.edges,
        }}
      >
        <main>
          <GlobalStyle />
          <Header />
          {children}
          <Footer />
        </main>
      </SiteContext.Provider>
    )}
  />
);

export default Layout;

// GraphQL Query
const SITE_QUERY = graphql`
  {
    settingsYaml(sectionName: { eq: "global" }) {
      logo
      site_title
      description
      phone
      address {
        cityStateZip
        street
      }
      hours
      email
    }
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, ext: { ne: ".pdf" } }
    ) {
      edges {
        node {
          id
          relativePath
          childImageSharp {
            fluid(quality: 90, maxWidth: 1500) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;
