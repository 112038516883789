import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Arrow = ({ content }) => (
  <div className="arrow">
    {content && (
      <AnchorLink href="#pageComponents">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="70"
          height="70"
          viewBox="0 0 70 70"
        >
          <defs>
            <clipPath id="clip-path">
              <rect width="16" height="16" fill="none" />
            </clipPath>
          </defs>
          <g
            id="Downward_Arrow"
            data-name="Downward Arrow"
            transform="translate(-659 -693)"
          >
            <circle
              id="Ellipse_47"
              data-name="Ellipse 47"
              cx="35"
              cy="35"
              r="35"
              transform="translate(659 693)"
              fill="rgba(255,255,255,0.17)"
            />
            <g
              id="Symbol_43_3"
              data-name="Symbol 43 – 3"
              transform="translate(686 720)"
              clipPath="url(#clip-path)"
            >
              <path
                id="Path_10"
                data-name="Path 10"
                d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                transform="translate(16) rotate(90)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      </AnchorLink>
    )}
  </div>
);

export default Arrow;
