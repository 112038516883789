import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import TeamListing from '../molecules/TeamListing';
import { hoefler } from '../../styles/utilities/fonts';
import { colors } from '../../styles/utilities/settings';
import { typography } from '../../styles/utilities/elements';
import { above, below } from '../../styles/utilities/mediaQueries';

export const TeamContext = React.createContext();

export default class TeamList extends Component {
  state = {
    activeIndex: 0,
  };

  render() {
    const { activeIndex } = this.state;
    const { content } = this.props;
    return (
      <StaticQuery
        query={TEAMLIST_QUERY}
        render={data => (
          <TeamContext.Provider
            value={{
              teamList: data.allMarkdownRemark.edges,
              activeIndex,
              updateIndex: num => {
                this.setState({
                  activeIndex: num,
                });
              },
            }}
          >
            <Wrapper id="teamContent">
              <STeamList>
                <div className="content">
                  <h3>{content.heading}</h3>
                  <h5>
                    {
                      data.allMarkdownRemark.edges[activeIndex].node.frontmatter
                        .title
                    }
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allMarkdownRemark.edges[activeIndex].node.html,
                    }}
                  />
                </div>
                <TeamListing />
              </STeamList>
            </Wrapper>
          </TeamContext.Provider>
        )}
      />
    );
  }
}

const TEAMLIST_QUERY = graphql`
  {
    allMarkdownRemark(sort: { fields: frontmatter___orderNumber }) {
      edges {
        node {
          id
          frontmatter {
            title
            image
          }
          html
        }
      }
    }
  }
`;

const STeamList = styled.div`
  padding: 50px 0;

  ${above.ipadPort`
    display: flex;
    padding: 100px 0;
  `}

  .content {
    ${above.ipadPort`
      max-width: 470px;
      width: 100%;
      padding-right: 50px;
    `}

    ${below.ipadPort`
      padding-bottom: 30px;
    `}

    > div {
      ${typography};
    }
  }

  h3 {
    ${hoefler};
    font-size: 30px;
    color: ${colors.lightBlue};
    margin-bottom: 15px;
  }

  h5 {
    display: inline-block;
    color: ${colors.white};
    background-color: ${colors.lightBlue};
    padding: 10px;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
    letter-spacing: 2px;
  }
`;
