import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { hoefler } from '../../styles/utilities/fonts';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, breakpoints } from '../../styles/utilities/settings';
import { below, above } from '../../styles/utilities/mediaQueries';
import GoogleMap, { SGoogleMap } from '../atoms/GoogleMap';

const ContactBlock = ({ content }) => (
  <StaticQuery
    query={CONTACTBLOCK_QUERY}
    render={data => (
      <SContactBlock>
        <Wrapper>
          <div className="content">
            <h1>{content.heading}</h1>
            <a href={`tel:${data.settingsYaml.phone}`}>
              {data.settingsYaml.phone}
            </a>

            <a
              className="address"
              href={`https://www.google.com/maps/dir//${data.settingsYaml.address.street} ${data.settingsYaml.address.cityStateZip}`}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{
                __html: `${data.settingsYaml.address.street}<br />${data.settingsYaml.address.cityStateZip}`,
              }}
            />

            <p>
              <strong>Directions:</strong>
              <br />
              {data.settingsYaml.address.directions}
            </p>
          </div>

          <GoogleMap
            address={data.settingsYaml.address}
            location={data.settingsYaml.location}
          />
        </Wrapper>
      </SContactBlock>
    )}
  />
);

export default ContactBlock;

export const SContactBlock = styled.div`
  background: linear-gradient(to right, ${colors.lightBlue} 0%, ${
  colors.darkBlue
} 100%);
  line-height: 1.3;

  ${above.ipadPort`
    height: calc(100vh - 118px);
    display: flex;
    align-items: center;
  `}

  ${below.ipadPort`
    padding: 60px 0;
  `}

  ${Wrapper} {
    ${above.ipadPort`
      display: flex;
      align-items: center;
    `}
  }

  .content {
    color: ${colors.white};
    ${above.ipadPort`
      width: 33.3333%;
      padding-right: 30px;
    `}

    ${below.ipadPort`
      padding: 0 0 30px;
    `}

    p {
      padding-bottom: 20px;
    }

    .address {
      font-size: 16px;
    }
  }

  ${SGoogleMap} {
    ${above.ipadPort`
      width: 66.6666%;
    `}
  }

  h1 {
    ${hoefler};
    font-size: 50px;
    margin-bottom: 0;
    line-height: 1;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      font-size: 32px;
    }
  }

  a {
    color: ${colors.white};
    margin-bottom: 20px;
    font-size: 30px;
    display: block;
  }
`;

const CONTACTBLOCK_QUERY = graphql`
  {
    settingsYaml(sectionName: { eq: "global" }) {
      address {
        cityStateZip
        street
        directions
      }
      location
      phone
    }
  }
`;
