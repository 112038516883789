import React from 'react';
import { graphql } from 'gatsby';
import Layout, { SiteContext } from '../Layout';
import Dochead from '../Dochead';
import PageBanners from '../PageBanners';
import PageComponents from '../PageComponents';

export const PageContext = React.createContext();

const Page = ({ data }) => (
  <Layout>
    <SiteContext.Consumer>
      {value => (
        <PageContext.Provider value={{ pageImage: data.pagesYaml.featuredImage }}>
          <Dochead title={data.pagesYaml.title !== 'Home' ? data.pagesYaml.title : null} siteName={value.global.site_title} description={value.global.description} pageImage={data.pagesYaml.featuredImage ? data.pagesYaml.featuredImage : null} />
          <PageBanners content={data.pagesYaml.pageBanners} />
          <PageComponents content={data.pagesYaml.widgets} />
        </PageContext.Provider>
      )}
    </SiteContext.Consumer>
  </Layout>
);

export default Page;

export const query = graphql`
query PageQuery($slug: String!) {
  pagesYaml(slug: {eq: $slug}) {
    slug
    title
    featuredImage
    pageBanners {
      heading
      copy
      arrow
      type
      link
      linkCopy
      name
    }
    widgets {
      type
      copy
      heading
      link
      linkCopy
      image
      name
      quote
      files {
        file
        title
      }
    }
  }
}
`;
