import React from 'react';
import HeroBanner from './organisms/HeroBanner';
import SpotlightBanner from './organisms/SpotlightBanner';

const PageBanners = ({ content }) => (content
  && (
  <section>
    {content && content.map(banner => banner.type === 'heroBanner'
      && <HeroBanner key={banner.heading} content={banner} />)}
    {content && content.map(banner => banner.type === 'staffSpotlightBanner'
      && <SpotlightBanner key={banner.heading} content={banner} />)}
  </section>
  )
);

export default PageBanners;
