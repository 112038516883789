import styled from 'styled-components';
import { colors, misc } from '../utilities/settings';
import { mediumBlueButton } from '../utilities/elements';
import { above, below } from '../utilities/mediaQueries';

const SHeader = styled.header`
  box-shadow: 0 0 20px -10px ${colors.black};
  padding: 0 0 20px;
  position: relative;
  z-index: 10;

  .logo {
    display: block;
    max-width: 310px;
    padding: 25px 0 0;

    ${below.ipadLand`
      max-width: 200px;
    `}
  }

  nav {
    ${above.ipadLand`
      display: flex;
      position: relative;
      align-items: flex-end;
      justify-content: space-between;
    `}

    &.open {
      &:before {
        ${below.ipadLand`
          opacity: 0.4;
          pointer-events: auto;
        `}
      }
      .menu {
        ${below.ipadLand`
          left: 20%;
        `}
      }
    }
    &:before {
      ${below.ipadLand`
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background-color: ${colors.black};
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 10;
        transition-duration: ${misc.animSpeed};
        pointer-events: none;
      `}
    }

    .menu {
      ${below.ipadLand`
        background-color: ${colors.white};
        padding: 30px 15px;
        height: 100%;
        position: fixed;
        top: 0;
        left: 100%;
        width: 80%;
        box-shadow: -25px 0 10px -30px ${colors.black};
        z-index: 99;
        transition-duration: ${misc.animSpeed};
      `}
    }
  }

  ul {
    li {
      ${above.ipadLand`
        display: inline-block;
      `}

      ${below.ipadLand`
        padding-bottom: 20px;
      `}

      + li {
        a {
          ${above.ipadLand`
            margin-left: 25px;
          `}

          ${below.ipadLand`
            padding-top: 20px;
            border-top: 1px solid ${colors.blue};
          `}
        }
      }

      a {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: ${colors.darkBlue};
        letter-spacing: 1.5px;
        transition-duration: ${misc.animSpeed};

        &:hover {
          ${above.ipadPort`
            opacity: 0.6;
          `}
        }

        &.button {
          ${above.ipadLand`
            opacity: 1;
            ${mediumBlueButton};
          `}
        }
      }
    }
  }

  .top-bar {
    background-color: ${colors.lightBlue};
    padding: 10px 15px;

    ${above.ipadLand`
      position: absolute;
      top: 0;
      right: 0;
      font-size: 15px;
    `}

    ${below.ipadLand`
      margin: 0 -15px;
      padding: 20px 15px;
    `}

    a {
      font-weight: 600;
      color: ${colors.white};

      ${below.ipadLand`
        display: block;
      `}

      + a {
        ${above.ipadLand`
          margin-left: 25px;
        `}

        ${below.ipadLand`
          margin-top: 20px;
        `}
      }

      i {
        position: relative;
        top: 1px;
        margin-right: 3px;
      }
    }
  }
`;

export default SHeader;
