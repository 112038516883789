import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';

const { GATSBY_GOOGLEMAP_API } = process.env;

const GoogleMap = ({ location }) => (
  <SGoogleMap>
    <div className="gmap_canvas">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GATSBY_GOOGLEMAP_API }}
        center={[
          JSON.parse(location).coordinates[1],
          JSON.parse(location).coordinates[0],
        ]}
        zoom={15}
      >
        <MapMarker
          lat={JSON.parse(location).coordinates[1]}
          lng={JSON.parse(location).coordinates[0]}
        />
      </GoogleMapReact>
    </div>
  </SGoogleMap>
);

export default GoogleMap;

export const SGoogleMap = styled.div`
  position: relative;
  text-align: right;
  height: 600px;

  .gmap_canvas {
    height: 100%;
    overflow: hidden;
    background: none;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;
