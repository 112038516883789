import React from 'react';
import styled from 'styled-components';
import { TeamContext } from '../organisms/TeamList';
import TeamTile, { STeamTile } from '../atoms/TeamTile';
import { above } from '../../styles/utilities/mediaQueries';

const TeamListing = () => (
  <TeamContext.Consumer>
    {value => (
      <STeamListing>
        {value.teamList.map(({ node }, index) => (
          <TeamTile key={node.id} content={node} index={index} />
        ))}
      </STeamListing>
    )}
  </TeamContext.Consumer>
);

export default TeamListing;

export const STeamListing = styled.div`

  ${above.mobile`
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
  `}

  ${above.ipadPort`
    width: 100%;
  `}

  ${STeamTile} {

    ${above.mobile`
      width: 50%;
    `}

    ${above.ipadPort`
      width: 33.3333%;
    `}

  }
`;
