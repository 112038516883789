import React from 'react';
import ImageFeature from './organisms/ImageFeature';
// import Testimonial from './organisms/Testimonial';
import ContactBlock from './organisms/ContactBlock';
import TeamList from './organisms/TeamList';
import ServiceList from './organisms/ServiceList';
import FileList from './organisms/FileList';
import PhotoBanner from './organisms/PhotoBanner';

const PageComponents = ({ content }) =>
  content && (
    <section id="pageComponents">
      {content.map((widget, index) => (
        <div key={`${widget.type}${index}`}>
          {widget.type === 'imageFeatureLarge' && (
            <ImageFeature content={widget} />
          )}
          {/* {widget.type === 'testimonial' && <Testimonial content={widget} />} */}
          {widget.type === 'photoBanner' && <PhotoBanner content={widget} />}
          {widget.type === 'contactBlock' && <ContactBlock content={widget} />}
          {widget.type === 'teamList' && <TeamList content={widget} />}
          {widget.type === 'servicesList' && <ServiceList content={widget} />}
          {widget.type === 'fileList' && <FileList content={widget} />}
        </div>
      ))}
    </section>
  );

export default PageComponents;
