import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import SHeroBanner from '../../styles/organisms/SHeroBanner';
import ImgFile from '../atoms/ImgFile';
import Wrapper from '../../styles/utilities/Wrapper';
import ServiceAccordion from '../molecules/ServiceAccordion';
import { above } from '../../styles/utilities/mediaQueries';

export const ServiceContext = React.createContext();

export default class ServiceList extends Component {
  state = {
    activeIndex: null,
  };

  render() {
    const { content } = this.props;
    const { activeIndex } = this.state;
    return (
      <StaticQuery
        query={SERVICELIST_QUERY}
        render={data => (
          <ServiceContext.Provider
            value={{
              services: data.settingsYaml.services,
              activeIndex,
              updateIndex: num => {
                this.setState({
                  activeIndex: num,
                });
              },
            }}
          >
            <SServiceList>
              <SHeroBanner spotlight contentBlock>
                <ImgFile image={content.image} />
                <Wrapper>
                  <div className="content">
                    <h1>{content.heading}</h1>
                    <ReactMarkdown source={content.copy} />
                  </div>
                  <ServiceAccordion />
                </Wrapper>
              </SHeroBanner>
            </SServiceList>
          </ServiceContext.Provider>
        )}
      />
    );
  }
}

const SERVICELIST_QUERY = graphql`
  {
    settingsYaml(sectionName: { eq: "serviceList" }) {
      services {
        body
        title
      }
    }
  }
`;

const SServiceList = styled.div`
  ${SHeroBanner} {
    ${above.ipadPort`
      height: auto;
      padding: 100px 0;
      min-height: calc(100vh - 118px);
    `}
  }
`;
