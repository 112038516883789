import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ImgFile from '../atoms/ImgFile';
import { colors, breakpoints } from '../../styles/utilities/settings';
import { hoefler } from '../../styles/utilities/fonts';
import { above, below } from '../../styles/utilities/mediaQueries';

const ImageFeature = ({ content }) => (
  <SImageFeature>
    <ImgFile image={content.image} />
    <div className="content">
      <div className="inner">
        <h3>{content.heading}</h3>
        <Link to={`/${content.link}`}>{content.linkCopy}</Link>
      </div>
    </div>
  </SImageFeature>
);

export default ImageFeature;

const SImageFeature = styled.div`
  background-color: ${colors.darkBlue};
  color: ${colors.white};

  ${above.ipadLand`
    display: flex;
  `}

  > div {
    width: 100%;
    line-height: 1.2;
  }

  .content {
    ${above.ipadLand`
      display: flex;
      align-items: center;
    `}

    ${below.ipadLand`
      padding: 50px 0;
    `}
  }

  .inner {
    max-width: ${breakpoints.pageWidth / 2}px;
    padding: 0 0 0 100px;

    ${below.pageWidth`
      padding: 0 0 0 50px;
    `}

    ${below.ipadLand`
      padding: 0 15px;
    `}
  }

  h3 {
    font-size: 35px;
    margin-bottom: 20px;

    ${below.ipadPort`
      font-size: 25px;
    `}
  }

  a {
    ${hoefler};
    color: ${colors.lightBlue};
    font-style: italic;
    font-size: 25px;

    ${below.ipadPort`
      font-size: 16px;
    `}
  }
`;
