import React from 'react';
import { Link } from 'gatsby';

const MenuItem = ({ content }) => (
  <li>
    {content.externalUrl ? (
      <a className={content.extraClasses ? content.extraClasses : null} href={content.url} rel="noopener noreferrer" target="_blank">{content.title}</a>
    ) : (
      <Link className={content.extraClasses ? content.extraClasses : null} to={`/${content.url}`}>{content.title}</Link>
    )}
  </li>
);

export default MenuItem;
