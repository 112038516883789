import React from 'react';
import Img from 'gatsby-image';
import { SiteContext } from '../Layout';
import { pathStripper } from '../../js/helpers';

const ImgFile = ({ image }) => (
  <SiteContext.Consumer>
    {value => value.files.map(({ node }) => node.relativePath === pathStripper(image)
      && <Img key={node.id} fluid={node.childImageSharp.fluid} />)}
  </SiteContext.Consumer>
);

export default ImgFile;
