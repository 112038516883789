import { css } from 'styled-components';

export const hoefler = css`
  font-family: "Hoefler Text A", "Hoefler Text B";
  font-style: normal;
  font-weight: 400;
`;

export const hoeflerBold = css`
  font-family: "Hoefler Text A", "Hoefler Text B";
  font-style: normal;
  font-weight: 700;
`;

export const verlag = css`
  font-family: "Verlag A", "Verlag B";
  font-style: normal;
  font-weight: 400;
`;
